/* Main container */
.ai-explorer {
  width: 100%;
  min-height: 100vh;
  position: relative;
  overflow: hidden;
}

.game-background {
  width: 100%;
  min-height: 100vh;
  background: linear-gradient(135deg, #1a1f35, #0f172a);
  position: relative;
  padding: 2rem;
  box-sizing: border-box;
}

.background-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: radial-gradient(circle at center,
    rgba(93, 93, 255, 0.1) 0%,
    rgba(60, 60, 255, 0.05) 50%,
    rgba(0, 0, 0, 0) 100%);
}

/* Header Styles */
.back-link {
  display: inline-block;
  color: #a8b1ff;
  text-decoration: none;
  font-size: 1.25rem;
  margin-bottom: 1rem;
  transition: all 0.3s ease;
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  background: rgba(255, 255, 255, 0.05);
}

.back-link:hover {
  background: rgba(255, 255, 255, 0.1);
  transform: translateX(-5px);
}

.game-explorer-header {
  text-align: center;
  margin-bottom: 2rem;
  position: relative;
  z-index: 1;
}

.game-explorer-title {
  font-size: 3.5rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
  background: linear-gradient(to right, #fff, #a8b1ff);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}

.game-explorer-subtitle {
  font-size: 1.5rem;
  color: #a8b1ff;
  margin-bottom: 1.5rem;
}

.score-display {
  display: inline-block;
  padding: 0.5rem 1.5rem;
  background: linear-gradient(135deg, #ff4e50, #f9d423);
  border-radius: 9999px;
}

.score-text {
  font-size: 1.5rem;
  font-weight: bold;
  color: #fff;
}

/* Main Game Content */
.game-content {
  width: 100%;
  max-width: 1400px;
  margin: 0 auto;
  position: relative;
  z-index: 1;
}

/* Concept Selection */
.concepts-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  width: 100%;
  margin: 0 auto;
}

.concept-card {
  background: rgba(255, 255, 255, 0.05);
  border-radius: 1rem;
  overflow: hidden;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.1);
  height: 100%;
  display: flex;
  flex-direction: column;
}

.concept-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.2);
}

.concept-card.completed {
  border: 2px solid rgba(16, 185, 129, 0.3);
}

.concept-card.completed:hover {
  border-color: rgba(16, 185, 129, 0.5);
}

.concept-icon-section {
  padding: 3rem 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.theme-perception .concept-icon-section {
  background: linear-gradient(135deg, #4e54c8, #8f94fb);
}

.theme-learning .concept-icon-section {
  background: linear-gradient(135deg, #11998e, #38ef7d);
}

.theme-language .concept-icon-section {
  background: linear-gradient(135deg, #fc4a1a, #f7b733);
}

.icon {
  width: 48px;
  height: 48px;
  color: white;
}

.completion-badge {
  position: absolute;
  top: 1rem;
  right: 1rem;
  background: linear-gradient(135deg, #10B981, #059669);
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 9999px;
  font-size: 1rem;
  font-weight: 600;
  box-shadow: 0 4px 12px rgba(16, 185, 129, 0.2);
  animation: fadeIn 0.3s ease;
}

.concept-info {
  padding: 1.5rem;
  background: rgba(255, 255, 255, 0.03);
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.concept-title {
  font-size: 1.75rem;
  color: white;
  margin-bottom: 0.75rem;
  text-align: center;
}

.concept-description {
  color: #a8b1ff;
  font-size: 1rem;
  line-height: 1.6;
  text-align: left;
  margin: 0.5rem 0;
  flex-grow: 1;
}

/* Content Interface */
.content-interface {
  width: 100%;
  margin: 0 auto;
  padding: 1.5rem;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 1rem;
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.1);
  box-sizing: border-box;
}

.content-section {
  padding: 1rem;
}

.section-title {
  font-size: 2rem;
  color: white;
  margin-bottom: 1.5rem;
  text-align: center;
}

.section-details {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.section-details li {
  font-size: 1.5rem;
  padding: 1.25rem;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 0.5rem;
  color: #e2e8f0;
  margin-bottom: 1rem;
  line-height: 1.6;
}

.navigation-buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 2rem;
}

.nav-button {
  padding: 0.75rem 1.5rem;
  background: rgba(255, 255, 255, 0.1);
  border: none;
  border-radius: 0.5rem;
  color: white;
  font-size: 1rem;
  cursor: pointer;
  transition: all 0.3s ease;
}

.nav-button:hover:not(:disabled) {
  background: rgba(255, 255, 255, 0.2);
}

.nav-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.next-button {
  background: linear-gradient(135deg, #4e54c8, #8f94fb);
}

.next-button:hover {
  background: linear-gradient(135deg, #5d63d7, #9ea4ff);
}

.content-progress {
  font-size: 1rem;
  color: #a8b1ff;
}

/* Game Interface */
.game-interface {
  width: 100%;
  max-width: 900px;
  margin: 0 auto;
  padding: 1.5rem;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 1rem;
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.1);
  box-sizing: border-box;
}

.input-container {
  display: flex;
  gap: 1rem;
  margin-bottom: 1.5rem;
}

.game-input {
  flex: 1;
  padding: 0.75rem 1rem;
  background: rgba(255, 255, 255, 0.05);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 0.5rem;
  color: white;
  font-size: 1rem;
}

.submit-button {
  padding: 0.75rem 1.5rem;
  background: linear-gradient(135deg, #4e54c8, #8f94fb);
  border: none;
  border-radius: 0.5rem;
  color: white;
  font-size: 1rem;
  cursor: pointer;
  transition: all 0.3s ease;
}

.submit-button:hover:not(:disabled) {
  background: linear-gradient(135deg, #5d63d7, #9ea4ff);
  transform: translateY(-2px);
}

.submit-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.messages-container {
  height: 400px;
  overflow-y: auto;
  padding: 1rem;
  background: rgba(0, 0, 0, 0.2);
  border-radius: 0.5rem;
  margin-bottom: 1rem;
}

.message {
  padding: 0.75rem 1rem;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 0.5rem;
  color: #e2e8f0;
  margin-bottom: 0.75rem;
  font-size: 1rem;
  line-height: 1.6;
  word-break: break-word;
}

.back-button {
  display: block;
  width: fit-content;
  margin: 2rem auto 0;
  padding: 0.75rem 1.5rem;
  background: rgba(255, 255, 255, 0.1);
  border: none;
  border-radius: 0.5rem;
  color: white;
  font-size: 1rem;
  cursor: pointer;
  transition: all 0.3s ease;
}

.back-button:hover {
  background: rgba(255, 255, 255, 0.2);
}

/* Responsive design for tablets */
@media (max-width: 1024px) {
  .game-background {
    padding: 1.5rem;
  }
  
  .game-explorer-title {
    font-size: 3rem;
  }
  
  .game-explorer-subtitle {
    font-size: 1.25rem;
  }
  
  .score-text {
    font-size: 1.25rem;
  }
  
  .section-title {
    font-size: 1.75rem;
  }
  
  .concept-title {
    font-size: 1.5rem;
  }
}

/* Responsive design for mobile */
@media (max-width: 768px) {
  .game-background {
    padding: 1rem;
  }
  
  .game-explorer-title {
    font-size: 2.5rem;
  }
  
  .game-explorer-subtitle {
    font-size: 1rem;
    margin-bottom: 1rem;
  }
  
  .score-display {
    padding: 0.4rem 1rem;
  }
  
  .score-text {
    font-size: 1rem;
  }
  
  .concepts-grid {
    grid-template-columns: 1fr;
    gap: 1.5rem;
  }
  
  .concept-icon-section {
    padding: 2rem 1.5rem;
  }
  
  .icon {
    width: 36px;
    height: 36px;
  }
  
  .completion-badge {
    font-size: 0.875rem;
    padding: 0.4rem 0.75rem;
  }
  
  .content-interface,
  .game-interface {
    padding: 1rem;
  }
  
  .section-details li {
    font-size: 0.875rem;
    padding: 1rem;
  }
  
  .navigation-buttons {
    flex-direction: column;
    gap: 1rem;
    align-items: center;
  }
  
  .nav-button,
  .submit-button {
    width: 100%;
    padding: 0.75rem 1rem;
  }
  
  .content-progress {
    margin: 0.5rem 0;
  }
  
  .input-container {
    flex-direction: column;
  }
  
  .messages-container {
    height: 300px;
  }
  
  .message {
    font-size: 0.875rem;
    padding: 0.75rem;
  }
}

/* Responsive design for small mobile */
@media (max-width: 480px) {
  .game-explorer-title {
    font-size: 2rem;
  }
  
  .concept-icon-section {
    padding: 1.5rem 1rem;
  }
  
  .icon {
    width: 28px;
    height: 28px;
  }
  
  .section-title {
    font-size: 1.5rem;
  }
  
  .section-details li {
    padding: 0.75rem;
  }
  
  .messages-container {
    height: 250px;
  }
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

