/* CSS Reset and Base Styles with Whimsical Theme */
@import url('https://fonts.googleapis.com/css2?family=Gaegu:wght@300;400;700&family=Quicksand:wght@400;500;600;700&display=swap');

:root {
  --primary-bg: linear-gradient(to bottom, #0f172a, #1e293b);
  --header-bg: rgba(15, 23, 42, 0.9);
  --text-primary: #f1f5f9;
  --text-secondary: #94a3b8;
  --accent-gradient: linear-gradient(to right, #60a5fa, #818cf8);
  --card-bg: rgba(15, 23, 42, 0.6);
  --border-color: rgba(255, 255, 255, 0.15);
  --shadow-color: rgba(0, 0, 0, 0.2);
  
  /* Playful color palette */
  --color-leaf: #76c893;
  --color-sky: #90e0ef; 
  --color-cat: #9d8189;
  --color-bench: #8ecae6;
  --color-accent: #ff9f1c;
}

body, html {
  min-height: 100vh;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  line-height: 1.6;
  background: var(--primary-bg);
  color: var(--text-primary);
  font-family: 'Quicksand', system-ui, sans-serif;
}

*, *:before, *:after {
  box-sizing: inherit;
}

/* Typography */
h1, h2, h3, h4, h5, h6 {
  font-family: 'Gaegu', cursive;
  margin-top: 0;
  font-weight: 700;
}

p {
  font-family: 'Quicksand', sans-serif;
  font-weight: 500;
}

img {
  max-width: 100%;
  height: auto;
}

/* Layout */
.site-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

/* Header Styles */
.site-header {
  background: var(--header-bg);
  padding: 1rem 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 2px solid var(--border-color);
  position: sticky;
  top: 0;
  z-index: 100;
  backdrop-filter: blur(8px);
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
}

.logo-container {
  display: flex;
  align-items: center;
  gap: 0.5rem; /* Smaller gap on mobile */
  flex-shrink: 0; /* Prevent shrinking */
  flex-basis: auto;
}

.logo-image {
  width: 4.5rem; /* Relative sizing instead of fixed pixels */
  height: 4.5rem;
  max-width: 70px; /* Maximum size constraint */
  max-height: 70px;
  border-radius: 50%;
  object-fit: cover;
  border: none;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.logo-image:hover {
  transform: scale(1.1) rotate(5deg);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
}

.logo-text {
  font-size: 2rem; /* Slightly smaller base font */
  font-weight: 700;
  background: var(--accent-gradient);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  text-shadow: 0 2px 10px rgba(96, 165, 250, 0.3);
  letter-spacing: 0.5px;
}

.main-nav {
  display: flex;
  gap: 1.2rem;
  flex-wrap: wrap;
  justify-content: center;
}

.nav-btn {
  background: transparent;
  color: var(--text-primary);
  border: 2px solid transparent;
  padding: 0.7rem 1.7rem;
  cursor: pointer;
  font-size: 1.6rem;
  border-radius: 0.75rem;
  transition: all 0.3s ease;
  font-family: 'Gaegu', cursive;
  font-weight: 700;
  letter-spacing: 0.5px;
}

.nav-btn:hover {
  background: rgba(255, 255, 255, 0.1);
  transform: translateY(-2px);
}

.nav-btn-active {
  background: var(--accent-gradient);
  color: white;
  box-shadow: 0 4px 10px rgba(59, 130, 246, 0.5);
  border: none;
  transform: translateY(-2px);
}

.social-links {
  display: flex;
  gap: 1.2rem;
}

.social-links a {
  color: var(--text-primary);
  transition: all 0.3s ease;
  transform-origin: center;
}

.social-links a:hover {
  color: var(--color-accent);
  transform: scale(1.2) rotate(5deg);
}

/* Main Content */
.main-content {
  flex: 1;
  padding: 2rem;
  overflow-y: auto;
}

/* Home Page */
.home-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: calc(100vh - 120px);
  overflow: hidden;
  position: relative;
  background: white !important; /* Force white background */
  padding: 1rem;
  border-radius: 1.5rem;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.15);
  margin: 0 auto;
  max-width: 1400px;
  width: 100%;
}

.home-illustration {
  max-width: 100%;
  width: auto;
  max-height: 80vh;
  height: auto;
  object-fit: contain;
  display: block;
  margin: 0 auto;
  background-color: white !important; /* Force white background */
  transition: opacity 0.3s ease-in-out;
}

.home-illustration.loading {
  opacity: 0;
}

.home-illustration.loaded {
  opacity: 1;
}

/* Image placeholder for loading state */
.image-placeholder {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: white;
  z-index: 5;
}

.placeholder-loader {
  width: 48px;
  height: 48px;
  border: 5px solid rgba(100, 100, 100, 0.3);
  border-radius: 50%;
  border-top-color: var(--color-accent);
  animation: spin 1s ease-in-out infinite;
}

@keyframes spin {
  to { transform: rotate(360deg); }
}

/* About Page */
.about-container {
  max-width: 800px;
  margin: 2rem auto;
  padding: 2.5rem;
  background: var(--card-bg);
  border-radius: 1.5rem;
  box-shadow: 0 8px 32px var(--shadow-color);
  backdrop-filter: blur(12px);
  border: 2px solid var(--border-color);
  position: relative;
  overflow: hidden;
}

.about-container::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 5px;
  background: var(--accent-gradient);
}

.section-title {
  font-size: 3.2rem;
  margin-bottom: 2rem;
  background: var(--accent-gradient);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  text-align: center;
  position: relative;
  display: inline-block;
}

.section-title::after {
  content: '';
  position: absolute;
  bottom: -10px;
  left: 0;
  width: 100%;
  height: 3px;
  background: var(--accent-gradient);
  border-radius: 3px;
}

.about-content {
  font-size: 1.4rem;
  line-height: 1.9;
}

/* Projects Page */
.projects-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
}

.project-card {
  background: var(--card-bg);
  border-radius: 1.5rem;
  padding: 2.5rem;
  margin-bottom: 2.5rem;
  box-shadow: 0 10px 30px var(--shadow-color);
  backdrop-filter: blur(12px);
  border: 2px solid var(--border-color);
  transition: all 0.4s ease;
  position: relative;
  overflow: hidden;
}

.project-card::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 5px;
  background: linear-gradient(to right, var(--color-leaf), var(--color-sky));
}

.project-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 15px 40px rgba(0, 0, 0, 0.25);
}

.project-title {
  font-size: 2.4rem;
  margin-bottom: 1.2rem;
  color: var(--color-accent);
  position: relative;
  display: inline-block;
}

.project-title::after {
  content: '';
  position: absolute;
  bottom: -8px;
  left: 0;
  width: 40%;
  height: 3px;
  background: var(--color-accent);
  border-radius: 3px;
}

.project-description {
  font-size: 1.4rem;
  margin-bottom: 2rem;
  line-height: 1.8;
}

.project-button {
  background: var(--accent-gradient);
  color: white;
  border: none;
  padding: 0.9rem 1.8rem;
  border-radius: 0.75rem;
  cursor: pointer;
  font-size: 1.3rem;
  transition: all 0.3s ease;
  box-shadow: 0 4px 12px rgba(59, 130, 246, 0.5);
  font-family: 'Gaegu', cursive;
  font-weight: 700;
  letter-spacing: 0.5px;
}

.project-button:hover {
  transform: translateY(-3px) scale(1.05);
  box-shadow: 0 8px 20px rgba(59, 130, 246, 0.6);
}

.project-button:active {
  transform: translateY(1px);
}

/* Blog Page */
.blog-container {
  max-width: 900px;
  margin: 0 auto;
  padding: 2rem;
}

.blog-post {
  background: var(--card-bg);
  border-radius: 1.5rem;
  padding: 2.5rem;
  margin-bottom: 2.5rem;
  box-shadow: 0 8px 32px var(--shadow-color);
  backdrop-filter: blur(12px);
  border: 2px solid var(--border-color);
  position: relative;
  overflow: hidden;
}

.blog-post h1 {
  font-size: 2.5rem;
}

.blog-post h2 {
  font-size: 2.2rem;
}

.blog-post h3 {
  font-size: 1.8rem;
}

.blog-post p, 
.blog-post li, 
.blog-post blockquote {
  font-size: 1.4rem;
  line-height: 1.8;
}

.blog-post::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 5px;
  background: linear-gradient(to right, var(--color-bench), var(--color-cat));
}

/* Media queries for portrait and landscape orientations */
@media (orientation: portrait) {
  .home-illustration {
    max-height: 60vh;
    width: auto;
  }
}

@media (orientation: landscape) and (max-height: 500px) {
  .home-container {
    min-height: calc(100vh - 100px);
  }
  
  .home-illustration {
    max-height: 75vh;
    width: auto;
  }
}

/* Responsive Design for different screen sizes */
@media (max-width: 1024px) {
  .site-header {
    padding:  1rem 3rem;
  }
  
  .nav-btn {
    font-size: 1.5rem;
    padding: 0.6rem 1.4rem;
  }
  
  .section-title {
    font-size: 2.8rem;
  }
  
  /* Tablet-specific styles */
  .home-container {
    border-radius: 1rem;
    padding: 0.75rem;
  }
  
  .home-illustration {
    max-height: 70vh;
  }
}

@media (max-width: 768px) {
  .site-header {
    flex-direction: column;
    gap: 1rem;
    padding: 1rem 0.5rem;
  }

  .main-nav {
    width: 100%;
    justify-content: space-between;
  }

  .nav-btn {
    padding: 0.5rem;
    font-size: 1.3rem;
  }

  .social-links {
    margin-top: 0.5rem;
  }

  .section-title {
    font-size: 2.4rem;
  }

  .project-title {
    font-size: 2rem;
  }
  
  .about-content, 
  .project-description {
    font-size: 1.2rem;
  }
  
  .home-container {
    padding: 0.5rem;
    margin: 0.5rem;
    width: calc(100% - 1rem);
  }
  
  .home-illustration {
    max-height: 60vh;
  }
  
  .main-content {
    padding: 1rem;
  }
}

@media (max-width: 480px) {
  .logo-container {
    gap: 0.3rem;
  }
  
  .logo-image {
    width: 2rem;
    height: 2rem;
  }
  
  .logo-text {
    font-size: 1.6rem;
  }
  
  .nav-btn {
    padding: 0.4rem 0.6rem;
    font-size: 1.1rem;
  }
  
  .section-title {
    font-size: 2rem;
  }
  
  .project-title {
    font-size: 1.8rem;
  }
  
  .main-content {
    padding: 0.75rem;
  }
  
  .about-container,
  .project-card,
  .blog-post {
    padding: 1.5rem;
    border-radius: 1rem;
  }
  
  .home-container {
    padding: 0.5rem;
    margin: 0.25rem;
    width: calc(100% - 0.5rem);
    min-height: calc(100vh - 180px);
    border-radius: 0.75rem;
  }
  
  .home-illustration {
    max-height: 50vh;
  }
  
  .project-button {
    font-size: 1.1rem;
    padding: 0.7rem 1.4rem;
    width: 100%;
    display: block;
    text-align: center;
  }
  
  .blog-post h1 {
    font-size: 1.8rem;
  }
  
  .blog-post h2 {
    font-size: 1.6rem;
  }
  
  .blog-post h3 {
    font-size: 1.4rem;
  }
  
  .blog-post p, 
  .blog-post li, 
  .blog-post blockquote {
    font-size: 1.1rem;
    line-height: 1.6;
  }
  
  .image-placeholder {
    border-radius: 0.75rem;
  }
  
  .placeholder-loader {
    width: 36px;
    height: 36px;
    border-width: 4px;
  }
  
  .social-links {
    gap: 0.8rem;
  }
}